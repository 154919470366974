<template>
  <div :class="classObj" class="app-wrapper">
    <div class="main-container">
      <div :class="{'nav_Top':true,'bgColor':isColor}" id="nav">
        
        <div class="nav-top-logo">
          <img src="../assets/img/index/logo-title.png" alt="" style="height:65px;">
        </div>
        <div class="right-nav">
          <!-- <div class="login"> -->
          <!-- <img src="../assets/img/index/logo-title.png" alt="" style="height:30px;"> -->
            <!-- <span style="margin-right: 10px;">登录</span>   |  <span style="margin-left: 10px;">注册</span> -->
          <!-- </div> -->
          <div style="display: flex;align-items: center;justify-content: center;margin-top: 13px;">
            <sidebar v-show="isimg" />
          </div>
        </div>
        

      </div>
      <app-main />

    </div>
  </div>
</template>

<script>
import { Navbar, Sidebar, AppMain, UserInfo, RightImg } from './components'
import {accountInfo} from '@/api'
import ResizeMixin from './mixin/ResizeHandler'

export default {
  name: 'Layout',
  components: {
    Navbar,
    Sidebar,
    AppMain,
    UserInfo,
    RightImg
  },
  data(){
    this.scrollDom = null;
    return {
      isimg:true,
      scrollDom:null,
      isColor:true
    }
  },
  mixins: [ResizeMixin],
  computed: {
    sidebar() {
      return this.$store.state.app.sidebar
    },
    device() {
      return this.$store.state.app.device
    },
    fixedHeader() {
      return this.$store.state.settings.fixedHeader
    },
    classObj() {
      return {
        hideSidebar: !this.sidebar.opened,
        openSidebar: this.sidebar.opened,
        withoutAnimation: this.sidebar.withoutAnimation,
        mobile: this.device === 'mobile'
      }
    }
  },
  watch:{
    $route(to,form) {
      console.log(to,form)
      if(to.fullPath != form.fullPath){
        this.scrollDom.scrollTop = 0
      }
    },
  },
  mounted() {

    this.$nextTick(()=>{
      this.handleScroll()
      
    })
  },
  methods: {
    async info(){
      await accountInfo({}).then(res=>{
        // console.log(res)
        if(res.result == 0){

          sessionStorage.setItem('userUrl',res.data.accountPortrait)
        }
      })
    },
    handleClickOutside() {
      this.$store.dispatch('app/closeSideBar', { withoutAnimation: false })
    },
    handleScroll() {
      // this.scrollDom = document.getElementsByClassName('app-main')[0];
      this.scrollDom = document.querySelector('#nav');
      this.initEvent(this.scrollDom)
    },
    initEvent(dom) {
      dom.addEventListener('scroll', this.scrollHander,true);
      console.log(dom)
    },
    scrollHander() {
      const top = this.scrollDom.scrollTop;
      
      console.log(111,top)
      if(top<800){
        this.isColor = true
      }else{
        this.isColor = true
      }
      console.log(this.isColor)
    }
  }
}
</script>

<style>
</style>
<style lang="scss" scoped>
  @import "~@/styles/mixin.scss";
  @import "~@/styles/variables.scss";

  
  .app-wrapper {
    @include clearfix;
    position: relative;
    height: 100%;
    width: 100%;
    &.mobile.openSidebar{
      position: fixed;
      top: 0;
    }
  }
  .drawer-bg {
    background: #000;
    opacity: 0.3;
    width: 100%;
    top: 0;
    height: 100%;
    position: absolute;
    z-index: 999;
  }

  .fixed-header {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 9;
    width: calc(100% - #{$sideBarWidth});
    transition: width 0.28s;
  }

  .hideSidebar .fixed-header {
    width: calc(100% - 54px)
  }

  .mobile .fixed-header {
    width: 100%;
  }

  .nav_Top{
    display: flex;
    justify-content: center;
    // height: 160px;
    padding:29px 260px 25px 260px;
    overflow: hidden;
    position: relative;
    z-index: 99;
    .login{
      height:40px;
      display: flex;
      justify-content: flex-end;
      // float: right;
      color: rgba(255,255,255,.6);
      line-height: 40px;

    }
    .login span:hover{
      color: #fff;
    }
    .nav_top_log{
      width: 172px;
      height: 74px;
      //margin: 15px 0;
      img{
        width: 100%;
        height: 100%;
        cursor: pointer;
      }
    }
    .nav_Top_img{
      width: 368px;
      height: 48px;
      margin-left: 20px;
    }
    .cutNavState{
      width: 39px;
      height: 32px;
      //background: red;
      margin-top: 5px;
      margin-left: 30px;
      img{
        width: 100%;
        height: 100%;
      }
    }
  }
  .bgColor{
    background: #252525;
    position: relative;
    z-index: 999;
  }
</style>
<style lang="less" scoped>

/deep/.bgColor .el-scrollbar .nav .submenu .el-submenu__title{
  color: rgba(255,255,255,.8) !important;
  font-weight: 400;
  
}
/deep/.bgColor .el-scrollbar .nav .submenuPadding{
  color: rgba(255,255,255,.8) !important;
  font-weight: 400;
  
}
/deep/.bgColor .el-scrollbar .nav .is-active{
  border: none;
  color: rgba(255,255,255,1) !important;
  
  &.submenuPadding{
    
    // background-image: url("../assets/img/navBg3_2.png") !important;
    //background-size: auto 100% !important;
    // background-size: 49px 24px !important;
    // background-repeat: no-repeat;
    // background-position: left calc(50% + 8px);
  }
 
  .el-submenu__title{
    // background-image: url("../assets/img/navBg3_2.png") !important;
    //background-size: auto 100% !important;
    background-size: 49px 24px !important;
    background-repeat: no-repeat;
    background-position: left calc(50% + 8px);
  }
}
/deep/.bgColor .el-scrollbar .nav .is-active span::after{
  content: '';
  width: 10px;
  height: 10px;
  border: 2px solid red;
  border-radius: 50%;
  position: absolute;
  bottom: 5px;
  left: 50%;
  transform: translateX(-50%);
  
}
/deep/.bgColor .el-scrollbar .nav {
  .el-menu-item,.el-submenu .el-submenu__title{
    
    &:hover{
      color: #fff !important;
      // background-image: url("../assets/img/navBg3_2.png") !important;
      background-size: 49px 24px !important;
      background-repeat: no-repeat;
      background-position: left calc(50% + 8px);
    }
    &:hover::after{
      content: '';
      width: 10px;
      height: 10px;
      border: 2px solid red;
      border-radius: 50%;
      position: absolute;
      bottom: 5px;
      left: 50%;
      transform: translateX(-50%);
      
    } 
  }
}
// /deep/.bgColor .el-scrollbar .nav{
//   .titleicon{
//     .titleicon_1{
//       background-color: #ffffff;
//       border-color: white;
//     }
//     .H{
//       color: white;
//     }
//     .titleicon_4{
//       background: #ffffff;
//       color: #007B8A;
//       &:hover{
//         background: #007B8A;
//         border: 1px solid #ffffff;
//         color: #ffffff;
//       }
//     }
//     .titleicon_5{
//       background: rgba(0,0,0,0);
//       border-color: white;
//       color: #ffffff;
//       &:hover{
//         background: #007B8A;
//         border: 1px solid #ffffff;
//         color: #ffffff;
//       }
//     }
//   }

// }
</style>
