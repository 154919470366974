import {get,post,Del,put} from '@/utils/request'
export const imgURL ='http://81.68.87.225:9809/'; //图片
export const imgUrl ='https://wx.duomirabbit.com/path/'; //图片
export const fileURL ='https://wx.ywcq.com/pyzzyph/wx/file/fileUpload'; //上传



export const getNewList=(data)=>post(`/webapi/article/index`,data) //首页-新闻列表

export const getHonorList=(data)=>post(`/webapi/honor/index`,data) //首页-荣誉列表

export const getPartnerList=(data)=>post(`/webapi/partner/list`,data) //首页-合作伙伴
export const getPartnerInfo=(data)=>post(`/webapi/partner/info`,data) //首页-合作伙伴详情

export const getTopicList=(data)=>post(`/webapi/topic/index`,data) //首页-专题

export const getLinkList=(data)=>post(`/webapi/link/list`,data) //首页-友情链接

export const getTopicLists=(data)=>post(`/webapi/topic/list`,data) //专题列表
export const getTopicInfo=(data)=>post(`/webapi/topic/info`,data) //专题列表


export const getArticleList=(data)=>post(`/webapi/article/list`,data) //新闻列表页
export const getArticleInfo=(data)=>post(`/webapi/article/info`,data) //新闻详情页

export const getchannelList=(data)=>post(`/webapi/channel/list`,data) //栏目分类列表
export const getguideList=(data)=>post(`/webapi/guide/query`,data) //新闻详情页

export const gethonorInfo=(data)=>post(`/webapi/honor/info`,data) //荣誉详情




