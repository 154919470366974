<template>
  <section class="app-main" ref="maxContextBox" id="app-main">
    
    <!-- <div> -->
      <rain :rainNumber="200" :w="5" :h="5" style="width: 100%;height:100%;position: fixed;top: 0;"></rain>
      <transition name="fade-transform" mode="out-in">
        <!-- <div style="position: relative;z-index: 99;"> -->
          <router-view :key="key"/>
        <!-- </div>  -->

        
      </transition>
    <!-- </div> -->
    
    <!-- <body class="thunder">
      <canvas id="canvas1"></canvas>
      <canvas id="canvas2"></canvas>
      <canvas id="canvas3"></canvas>
    </body> -->
    <!--  底部备案块  -->
    <div :class="link=='honorInfo'||link=='trendsInfo'||link=='specialInfo'?'bottomCons':'bottomCon'">
      <div class="content">
        <div class="left">
          <img src="../../assets/img/index/logo-title.png" alt="" class="logo" style="margin-bottom: 5px;">
          <div class="phone" style="margin-bottom: 5px;">官方服务热线  010-56928020</div>
          <div class="time">周一至周五 9:00—18:00</div>
        </div>
        <div class="middle">
          <div class="texts" v-for="(item,index) in linkList" @click="goLink(item)">{{ item.name }}</div>
        </div>
        <div class="right">
          <img src="../../assets/img/index/qrcode.png" alt="" class="qrcode">
        </div>
      </div>
      <div class="links">
        <div class="link">友情链接： 
          <span v-for="(item,index) in yqLink">
            <a :href="item.linkUrl">{{item.linkName}}</a> 
            <span v-if="index!=yqLink.length-1" style="margin:0 10px;">|</span>
          </span>
        </div>
        <div class="link" style="margin-top: 10px;display: flex;align-items: center;">
          <img src="../../assets/img/index/jh.gif" alt="" style="width:20px;height:22px;margin-right: 10px;">
          <img src="../../assets/img/index/jh.png" alt="" style="width:20px;height:22px;;margin-right: 10px;">
          京公网安备 11010802041369 京ICP备2021013383号-12
          <!-- <span v-for="(item,index) in yqLink">
            <a :href="item.linkUrl">{{item.linkName}}</a> 
            <span v-if="index!=yqLink.length-1" style="margin:0 10px;">|</span>
          </span> -->
        </div>
      </div>
      
    </div>

  </section>
</template>

<script>
import {getLinkList} from '@/api'
import rain from './rain.vue'
export default {
  components:{
    rain
  },
  name: 'AppMain',
  data(){
    return {
      tType:'CH',
      isShow:false,
      navList:[],
      linkList:[
        {name:'用户帮助',link:''},
        {name:'平台协议',link:'https://cacc.cacc.com/agreement'},
        {name:'关于华版',link:''},
        {name:'意见反馈',link:''},
        {name:'用户协议',link:''},
        {name:''},
        {name:'常见问题',link:''},
        {name:'隐私政策',link:'https://cacc.cacc.com/policy'},
        
      ],
      yqLink:[],
      link:''
    }
  },
  computed: {
    key() {
    //   let main=JSON.parse(sessionStorage.getItem("router"))[3]
    //   main=main.path+'/'+main.children[0].path
    //   console.log(main)
    //   return main
      // console.log(this.$route.path)
      if(this.$route.path == '/'){
        return 'home'
      }else{
        return this.$route.path
      }

      // console.log(JSON.parse(sessionStorage.getItem("router")))
    }

  },
  
  watch:{
    $route(to,form) {
      console.log(to,form)
      this.link=to.name
      // if(to.path == '/hotelHomestay/hotelHomestayTwo'){
      //   this.isShow = false
      // }else{
      //   this.isShow = true
      // }
    },
    // deep: true
  },
  created() {
    // let main=JSON.parse(sessionStorage.getItem("router"))[3]
    // main=main.path+'/'+main.children[0].path
    // console.log(main)
    // console.log(this.$route)
    this.tType = sessionStorage.getItem('textType')
    // console.log(this.$router.options.routes)
    this.$router.options.routes.forEach((item,index)=>{
      if(item.path!='/404' && item.path!='/' && item.path!='*'){
        this.navList.push(item)
      }
    })
    this.getLink()

  },
  mounted() {
    // this.$nextTick(()=>{
    //   this.handleScroll()
      // this.initEvent(this.scrollDom)
    // })
    // document.querySelector('#app-main')
    // window.addEventListener("scroll",this.handleScroll())

    // let  nav = document.createElement('script')//创建script标签
    // nav.type = 'text/javascript'; 
    // nav.src = require('./AppMain.js')  //静态资源
  },
  methods:{
    getLink(){
      getLinkList({}).then(res=>{
        // console.log(res)
        if(res.code==200){
          this.yqLink=res.data
        }
      })
    },
    goLink(item){
      window.open(item.link,'_blank')
    },
    handleScroll() {
      console.log(123)
      /* 获取回到顶部的位置元素 .content-container-top */
      // let dom =document.querySelector('#app-main').scrollTop;
      // let dom = this.$refs.maxContextBox;
      // this.scrollDom = this.$refs.maxContextBox;
      // this.scrollTop = dom.scrollTop;
      // console.log(dom)
      var top = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      console.log(top)

    },
    initEvent(dom) {
      dom.addEventListener('scroll', this.scrollHander);
    },
    scrollHander() {
      const top = this.scrollDom.scrollTop;
    },
    go(i){
      console.log(i)
      if(i==1){
        window.location.href='http://wlt.jiangsu.gov.cn'
      }else if(i==2){
        window.location.href='http://wglj.yangzhou.gov.cn'
      }else if(i==3){
        window.location.href='http://www.jstour.com'
      }else if(i==4){
        window.location.href='https://www.mct.gov.cn'
      }

    }
  },
}
</script>
<style scoped>
/* @import url(./AppMain.scss); */
</style>

<style scoped>


.maxContextBox{
  background-image:url("http://admin.wxjngyh.com/profile/upload/2022/05/26/%E5%A4%A7%E8%83%8C%E6%99%AF_20220526112154A017.png") ;
  background-size: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
}
.app-main {
  /*50 = navbar  */
  /* max-height: calc(100vh - 120px);    减顶部的130px和底部的250px */
  /*width: 100%;*/
  position: relative;
  /*overflow: auto;*/
  /* overflow-y: scroll; 
 overflow-x: hidden; */
  background: #fff;
  /* background: url('../../assets/img/index/bg.jpg'); */
  background-size:  cover;
  background-repeat: no-repeat;
}
.app-main::-webkit-scrollbar {
  width: 6px;
  /*height: 4px;*/
}
.app-main::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  background: rgba(0,0,0,0.2);
}
.app-main::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0,0,0,0.2);
  border-radius: 0;
  background: rgba(0,0,0,0.1);

}
.fixed-header+.app-main {
  padding-top: 50px;
}
</style>

<style lang="scss" scoped>
.bottomCons{
  width: 100%;
  height: 762px;
  background-image: url('../../assets/img/index/bottom-bg-flower.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 99;
  .content{
    width: 1400px;
    height: calc(574px - 256px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    // justify-content: space-between;
    flex-wrap: nowrap;
    .left{
      width: 300px;
      height: 230px;
      padding-top: 13px;
      border-right: 1px solid rgba(214, 177, 133, .2);
      .logo{
        width: 174px;
        margin-left: 20px;
      }
      .phone,.time{
        width: 215px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255,255,255,.5);
        text-align: center;
      }
    }
    .middle{
      width: 728px;
      height: 230px;
      padding-top: 13px;
      display: flex;
      flex-flow: row wrap;
      // justify-content: space-between;
      border-right: 1px solid rgba(214, 177, 133, .2);
      .texts{
        width: 33.33%;
        height: 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255,255,255,.5);
        text-align: center;
        // line-height: 50px;
      }
      .texts:hover{
        color: #FFFFFF;
      }
    }
    .right{
      padding-top: 13px;
      .qrcode{
        margin-left: 100px;
        width: 160px;
        height: 160px;
      }
    }
  }
  .links{
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    .link{
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255,255,255,.5);
      text-align: center;
    
    }
    .link a:hover{
      color: rgba(255,255,255,1);
    }
  }
  
}
.bottomCon{
  width: 100%;
  height: 574px;
  background-image: url('../../assets/img/index/bottom-bg.png');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  position: relative;
  z-index: 99;
  .content{
    width: 1400px;
    height: calc(574px - 256px);
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    // justify-content: space-between;
    flex-wrap: nowrap;
    .left{
      width: 300px;
      height: 230px;
      padding-top: 13px;
      border-right: 1px solid rgba(214, 177, 133, .2);
      .logo{
        width: 174px;
        margin-left: 20px;
      }
      .phone,.time{
        width: 215px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255,255,255,.4);
        text-align: center;
      }
    }
    .middle{
      width: 728px;
      height: 230px;
      padding-top: 13px;
      display: flex;
      flex-flow: row wrap;
      // justify-content: space-between;
      border-right: 1px solid rgba(214, 177, 133, .2);
      .texts{
        width: 33.33%;
        height: 50px;
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: rgba(255,255,255,.7);
        text-align: center;
        // line-height: 50px;
      }
      .texts:hover{
        color: #FFFFFF;
      }
    }
    .right{
      padding-top: 13px;
      .qrcode{
        margin-left: 100px;
        width: 160px;
        height: 160px;
      }
    }
  }
  .links{
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    .link{
      font-size: 19px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba(255,255,255,.5);
      text-align: center;
    
    }
    .link a:hover{
      color: rgba(255,255,255,1);
    }
  }
}
// fix css style bug in open el-dialog
.el-popup-parent--hidden {
  .fixed-header {
    padding-right: 15px;
  }
}
</style>

<style scoped lang="less">
  .filingInfo{
    height: 250px;
    overflow: hidden;
    //background: red;
    .bottomLogo{
      width: 170px;
      height: 75px;
      margin: 20px auto;
      img{
        width: 100%;
        height: 100%;
      }
    }
    .listPage{
      display: flex;
      justify-content: center;
      //align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 44px;
      span{
        margin: 0 15px;
      }
    }
    .newsPath{
      display: flex;
      justify-content: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
      margin-bottom: 7px;
      span:nth-child(1){
        margin-right: 20px;
      }
    }
    .filing{
      text-align: center;
      font-size: 16px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 22px;
    }
  }
  
  .div2{
    border-top: 1px solid #FFFFFF22;
    font-size: 14px;
    font-family: STSongti-SC-Regular, STSongti-SC;
    font-weight: 400;
    color: #FFFFFF;
    padding: 12px 0 17px;
    text-align: center;
  }
  .alert a,.div2 a{
    &:hover{
      color: #20a0ff;
    }
  }
  /deep/.el-backtop{
    background: none;
    width: auto;
    height: auto;
  }
  .el-menu--horizontal{
    border-radius: 20px;
    overflow: hidden;
    /deep/.el-menu--popup{
      border-radius: 20px;
    }
  }
</style>
