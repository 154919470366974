<template>
  <div v-if="!item.hidden">
    <!--  中文导航  -->
    <div v-if="zhen != 'EN'">
      <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
        <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
          <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenuPadding':true,'submenu-title-noDropdown':!isNest}">
            <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="onlyOneChild.meta.title" />
          </el-menu-item>
        </app-link>
      </template>

      <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body class="submenu">
        <template slot="title">
          <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.title" />
        </template>
        <sidebar-item

            v-for="child in item.children"
            :key="child.path"
            :item="child"
            :base-path="resolvePath(child.path)"
            class="nest-menu"
        />

      </el-submenu>
    </div>
    <!--  英文导航  -->
    <div v-else>
      <template v-if="hasOneShowingChild(item.children,item) && (!onlyOneChild.children||onlyOneChild.noShowingChildren)&&!item.alwaysShow">
        <app-link v-if="onlyOneChild.meta" :to="resolvePath(onlyOneChild.path)">
          <el-menu-item :index="resolvePath(onlyOneChild.path)" :class="{'submenuPadding':true,'submenu-title-noDropdown':!isNest}">
            <item :icon="onlyOneChild.meta.icon||(item.meta&&item.meta.icon)" :title="onlyOneChild.meta.ENtitle||onlyOneChild.meta.title" />
          </el-menu-item>
        </app-link>
      </template>

      <el-submenu v-else ref="subMenu" :index="resolvePath(item.path)" popper-append-to-body class="submenu">
        <template slot="title">
          <item v-if="item.meta" :icon="item.meta && item.meta.icon" :title="item.meta.ENtitle||item.meta.title" />
        </template>
        <sidebar-item

            v-for="child in item.children"
            :key="child.path"
            :item="child"
            :base-path="resolvePath(child.path)"
            class="nest-menu"
        />

      </el-submenu>
    </div>

  </div>
</template>

<script>
import path from 'path'
import { isExternal } from '@/utils/validate'
import Item from './Item'
import AppLink from './Link'
import FixiOSBug from './FixiOSBug'

export default {
  name: 'SidebarItem',
  components: { Item, AppLink },
  mixins: [FixiOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true
    },
    isNest: {
      type: Boolean,
      default: false
    },
    basePath: {
      type: String,
      default: ''
    },
    textType: {
      type: Number,
      default: 0
    }
  },
  created() {
    // console.log('获取存储值：',sessionStorage.getItem('textType'))
    this.zhen = sessionStorage.getItem('textType')
  },
  data() {
    // To fix https://github.com/PanJiaChen/vue-admin-template/issues/237
    // TODO: refactor with render function
    this.onlyOneChild = null
    return {
      zhen:'EN'
    }
  },
  // computed(){
  //
  // },
  methods: {
    hasOneShowingChild(children = [], parent) {
      const showingChildren = children.filter(item => {
        if (item.hidden) {
          return false
        } else {
          // Temp set(will be used if only has one showing child)
          this.onlyOneChild = item
          return true
        }
      })

      // When there is only one child router, the child router is displayed by default
      if (showingChildren.length === 1) {
        return true
      }

      // Show parent if there are no child router to display
      if (showingChildren.length === 0) {
        this.onlyOneChild = { ... parent, path: '', noShowingChildren: true }
        return true
      }

      return false
    },
    resolvePath(routePath) {
      if (isExternal(routePath)) {
        return routePath
      }
      if (isExternal(this.basePath)) {
        return this.basePath
      }
      return path.resolve(this.basePath, routePath)
    }
  }
}
</script>
<style scoped lang="less">
.el-menu-item{
  font-size: 18px;
}
  .submenuPadding{
    // padding-left: 20px !important;
    //text-align: center;
  }
  /deep/.el-menu-item,/deep/.el-submenu .el-submenu__title{
    &:hover{
      color: #C30D23 !important;
    }
  }
//.nest-menu{
//  background-color: #EAF2F3 !important;
//  padding: 0;
//}
.submenu{
  //background-color: #EAF2F3 !important;
  background: rgba(0,0,0,0)!important;
}
  .nest-menu /deep/.el-menu-item{
    background-color: #f0f0f0 !important;
    font-size: 20px !important;
    font-family: STSongti-SC-Black, STSongti-SC;
    color: rgba(255,255,255,.6);
    padding: 15px;
    box-sizing: content-box;
    // padding-left: 20px;
    //font-weight: 600;
    /*line-height: 20px;*/
    &:hover{
      //background-color: #007B8A !important;
      //color: #FFFFFF !important;
      //font-weight: 900;
      color: rgb(195, 13, 35) !important;
    }
  }
  /deep/.el-submenu__icon-arrow{
    top: 55%;
    right: 0;
    display: none;
  }
</style>
