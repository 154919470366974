import axios from 'axios'
import {MessageBox, Message} from 'element-ui'
import store from '@/store'
import {getToken, removeToken} from '@/utils/auth'
import router from "@/router";

// 创建axios实例
const service = axios.create({
  baseURL: '/api',
  timeout: 6000 // request timeout
})
/* 判断环境 */
if (process.env.NODE_ENV == 'development') {
  service.defaults.baseURL = 'https://web-portal.hbcacc.com';
  // service.defaults.baseURL = 'https://hbwebapi.cacc.com';
} else if (process.env.NODE_ENV == 'debug') {
  service.defaults.baseURL = '/api';
} else if (process.env.NODE_ENV == 'production') {
  service.defaults.baseURL = 'https://web-portal.hbcacc.com';
  // service.defaults.baseURL = 'https://hbwebapi.cacc.com';
}
//携带cookies
service.defaults.withCredentials = false
//post请求头的设置
service.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';
// request interceptor
service.interceptors.request.use(
  config => {
    // if (getToken()) {
      // debugger
      config.headers['lan']  = sessionStorage.getItem('textType')
    // }

    return config
  },
  error => {
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// axios拦截器
service.interceptors.response.use(
  response => {
    const res = response.data
    if(res.result === 600){
      MessageBox.confirm('当前设备登录信息已过期', '', {
        confirmButtonText: '重新登录',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // console.log(123)
        router.push({path:'/'})
      })
      return;
    }else if (res.result !== 0 && !res.success && res.code!=200) {
      // Message({
      //   message: res.message || 'Error',
      //   type: 'error',
      //   duration: 5 * 1000
      // })
      return;

    } else {
      return res
    }
  },
)
/* get请求 */
export const get = (url, params, config = {}) => {
  return new Promise((resolve, reject) => {
    service({
      method: 'get',
      url,
      params,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      if (!getToken()) {
        router.push("/login")
      }
      reject(error)
    })
  })
}

/* post请求  */
export const post = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    service({
      method: 'post',
      url,
      data,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      if (!getToken()) {
        router.push("/login")
      }
      reject(error)
    })
  })
}
/* del请求  */
export const Del = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    service({
      method: 'delete',
      url,
      data,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      if (!getToken()) {
        router.push("/login")
      }
      reject(error)
    })
  })
}
/* put请求  */
export const put = (url, data, config = {}) => {
  return new Promise((resolve, reject) => {
    service({
      method: 'put',
      url,
      data,
      ...config
    }).then(response => {
      resolve(response)
    }).catch(error => {
      if (!getToken()) {
        router.push("/login")
      }
      reject(error)
    })
  })
}

export default service
