import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/* Layout */
import Layout from '@/layout'
export const constantRoutes = [
  {
    path: '/404',
    component: () => import('@/views/404'),
    hidden: true
  },
  {
    path: '/',
    component: Layout,
    meta: { title: '首页', ENtitle:'Home', icon: '' },
    children: [
      {
        path: '/',
        name: '/',
        component: () => import('@/views/index/index.vue'),
        meta: { title: '首页', ENtitle:'Home', icon: '' }
      }
    ]
  },
  {
    path: '/introduction',
    component: Layout,
    meta: { title: '关于我们', ENtitle:'Home', icon: '' },
    children: [
      {
        path: '/introduction',
        name: 'introduction',
        component: () => import('@/views/about/introduction.vue'),
        meta: { title: '中心简介', ENtitle:'about', icon: '' }
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/index.vue'),
        meta: { title: '联系我们', ENtitle:'about', icon: '' }
      }
      
    ]
  },
  {
    path: 'https://www.cacc.com/',
    component: Layout,
    meta: { title: '业务公开', ENtitle:'Home', icon: '' },
    children: [
      {
        path: 'https://www.cacc.com/',
        name: 'business',
        // component: () => import('@/views/business/index.vue'),
        meta: { title: '业务公开', ENtitle:'business', icon: '' }
      }
    ]
  },
  {
    path: '/guide',
    component: Layout,
    meta: { title: '用户指南', ENtitle:'Home', icon: '' },
    children: [
      {
        path: '/guide',
        name: 'guide',
        component: () => import('@/views/guide/index.vue'),
        meta: { title: '登记指南', ENtitle:'guide', icon: '' }
      },
      {
        path: '/help',
        name: 'help',
        component: () => import('@/views/guide/help.vue'),
        meta: { title: '维权指南', ENtitle:'guide', icon: '' }
      },
      {
        path: '/existing',
        name: 'existing',
        component: () => import('@/views/guide/existing.vue'),
        meta: { title: '存证指南', ENtitle:'guide', icon: '' }
      },
      {
        path: '/transactions',
        name: 'transactions',
        component: () => import('@/views/guide/transaction.vue'),
        meta: { title: '交易指南', ENtitle:'guide', icon: '' }
      },
    ]
  },
  {
    path: '/trends',
    component: Layout,
    meta: { title: '要闻动态', ENtitle:'Home', icon: '' },
    children: [
      {
        path: '/trends',
        name: 'trends',
        component: () => import('@/views/trends/index.vue'),
        meta: { title: '要闻动态', ENtitle:'trends', icon: '' }
      },
      {
        path: '/trendsInfo',
        name: 'trendsInfo',
        component: () => import('@/views/trends/info.vue'),
        meta: { title: '要闻动态详情', ENtitle:'trends', icon: '' },
        hidden: true
      },
      {
        path: '/ecology',
        name: 'ecology',
        component: () => import('@/views/ecology/index.vue'),
        meta: { title: '华版生态', ENtitle:'ecology', icon: '' }
      },
      {
        path: '/hotspot',
        name: 'hotspot',
        component: () => import('@/views/hotspot/index.vue'),
        meta: { title: '实时热点', ENtitle:'hotspot', icon: '' }
      },
    ]
  },
  // {
  //   path: '/ecology',
  //   component: Layout,
  //   meta: { title: '华版数字生态', ENtitle:'Home', icon: '' },
  //   children: [
  //     {
  //       path: '/ecology',
  //       name: 'ecology',
  //       component: () => import('@/views/ecology/index.vue'),
  //       meta: { title: '华版数字生态', ENtitle:'ecology', icon: '' }
  //     }
  //   ]
  // },
  {
    path: '/transaction',
    component: Layout,
    meta: { title: '数字化交易', ENtitle:'Home', icon: '' },
    children: [
      {
        path: '/transaction',
        name: 'transaction',
        component: () => import('@/views/transaction/index.vue'),
        meta: { title: '数字化交易', ENtitle:'ecology', icon: '' }
      }
    ]
  },
  {
    path: '/special',
    component: Layout,
    meta: { title: '专题专栏', ENtitle:'Home', icon: '' },
    hidden:true,
    children: [
      {
        path: '/special',
        name: 'special',
        component: () => import('@/views/special/index.vue'),
        meta: { title: '专题专栏', ENtitle:'ecology', icon: '' },
        
      },
      {
        path: '/specialInfo',
        name: 'specialInfo',
        component: () => import('@/views/special/info.vue'),
        meta: { title: '专题专栏', ENtitle:'ecology', icon: '' },
        hidden:true
      },
    ]
  },
  {
    path: '/honor',
    component: Layout,
    meta: { title: '专题专栏', ENtitle:'Home', icon: '' },
    hidden:true,
    children: [
      {
        path: '/honorInfo',
        name: 'honorInfo',
        component: () => import('@/views/honor/info.vue'),
        meta: { title: '荣誉资质详情', ENtitle:'ecology', icon: '' },
        hidden:true
      }
    ]
  },

  { path: '*', redirect: '/404', hidden: true }
]

/**
 * asyncRoutes
 * the routes that need to be dynamically loaded based on user roles
 */

const createRouter = () => new Router({
  mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRoutes
})

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router

